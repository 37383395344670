@import './dashboard.min.css';
@import './grapes.css';

.flex-fill {
    flex-grow: 1;
    max-width: 100%;
    display: flex !important;
}

.rbc-day-slot.rbc-today, .rbc-day-bg.rbc-today {
    background-color: transparent !important;
}

.rbc-time-slot {
    min-height: 30px;
}

.rbc-time-slot:hover {
    background-color: #ededed;
}

.object-fit-contain {
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.object-fit-cover {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

button, html [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: none !important;
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #1ab394 !important;
    border-color: #1ab394 !important;
}

.component-container {
    min-height: 100px;
}

.editable-component-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.shadow-sm {
    box-shadow: rgb(225, 225, 225) 0 0 10px;
}

.overflow-scroll {
    overflow: scroll;
}

.overflow-hidden {
    overflow: hidden;
}

.tittle {
    position: relative;
}

.tittle h2 {
    background: transparent;
    display: inline-block;
    position: relative;
    padding: 0 5%;
    z-index: 999;
    color: #282828;
    font-weight: 400;
}

.tittle::before {
    background: rgb(235, 235, 235) none repeat scroll 0 0;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    top: 17px;
    width: 45%;
}

.tittle::after {
    background: rgb(235, 235, 235) none repeat scroll 0 0;
    content: "";
    height: 1px;
    right: 0;
    position: absolute;
    top: 17px;
    width: 45%;
}

.cursor-pointer {
    cursor: pointer;
}

.opacity-0 {
    opacity: 0;
}
