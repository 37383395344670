html,
body,
#root {
  height: 100vh;
}

html {
  font-size: 14px;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.btn.focus,
.btn:focus,
button:focus {
  box-shadow: none !important;
  outline: 0 !important;
}

.drawer {
  width: 260px;
}

.drawer-hidden {
  width: 0;
}

.drawer-header {
  min-height: 64px;
}

.content {
  width: calc(100% - 260px);
  float: right;
}

.content.content-lg {
  width: 100%;
}

.card-header h6 {
  line-height: 1.98;
}

.dropdown-toggle::after {
  display: none !important;
}

.Toastify__toast--error {
  background: #dc3545 !important;
}
textarea {
  min-height: 100px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
}

.blur {
  filter: blur(4px);
}

/* login:start */
.role-selection h1 {
  font-size: 100%;
  margin-bottom: 20px;
}

.role-selection button {
  margin-bottom: 20px;
}
/* login:end */

/* button:start */
.btn-100 {
  min-width: 100px;
}
/* button:end */

/*tools:start*/
.ml1rem {
  margin-left: 1rem;
}

.mb10 {
  margin-bottom: 10px;
}
.mr10 {
  margin-right: 10px;
}

.mr5 {
  margin-right: 5px;
}

.mt20 {
  margin-top: 20px;
}

.mb20 {
  margin-bottom: 20px;
}

.pt20 {
  padding-top: 20px;
}

.tc {
  text-align: center;
}
.tr {
  text-align: right;
}
/*tools:end*/

/* color:start */
.gray {
  color: gray;
}
/* color:end */

.primary-hover:hover {
  color: #1ab394 !important;
  text-decoration: none;
}

.primary-hover:hover * {
  color: #1ab394 !important;
}

/*.react-datepicker__time-container {*/
/*  width: 100px !important;*/
/*}*/

/*.react-datepicker__time-box {*/
/*  width: 100px !important;*/
/*}*/

.react-datepicker-wrapper{
  display: block !important;
}

.react-datepicker__input-container{
  display: block !important;
}

.rbc-month-view{
  height: auto !important;
}

.omniwe-modal-title h5{
  width:100%;
  display:flex;
  justify-content: space-between;
}

.nav-link{
  cursor: pointer;
}

/*.ClientScheduleWrapper .rbc-event {*/
/*  background-color: #1ab394;*/
/*}*/
